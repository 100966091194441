<template>
  <div class="conversations relative max-height-page overflow-y-auto padding-m-bottom">

    <spinner v-if="asyncStatus.asyncDataLoading" size="2"></spinner>
    <!--
    <icon-halo
      halo-theme="lead"
      theme="bg"
      icon="plus"
      class="absolute z-level-top-1"
      @click="
        startConversationByState(['f0f47d1a-edd8-41c6-8bd0-77a28fb5279b'])
      "></icon-halo>
    -->

    <div
      v-for="conversation in conversations"
      v-if="conversations"
      class="conversation-intro padding-l-vertical cursor-pointer"
      :class="{ current: conversation.uuid === conversationUuid }"
      @click="selectConversation(conversation)">
      <div class="flex gap-m">
        <div class="user-avatars relative">
          <chat-user-avatar
            v-for="profile in getConversationUsers(conversation)"
            v-bind="profile">
          </chat-user-avatar>
        </div>
        <div class="content">
          <strong
            class="block text-l conversation-participants"
            :title="getConversationTitle(conversation)">
            {{ getConversationTitle(conversation) }}
          </strong>
          <p class="last-message-content block margin-remove">
            {{ getFirstMessage(conversation)?.content }}
          </p>
        </div>
      </div>
      <div class="flex last-message-time width-expand flex-end">
        <span class="text-s c-gray-5">{{
          translateDate(sampleDate, "dateWithTime")
        }}</span>
      </div>
    </div>
    <div v-if="!asyncStatus.asyncDataLoading && conversations.length < 1">
      <h3>{{ translateUcFirst("chat.conversation.noConversationsTitle") }}</h3>
    </div>
  </div>
</template>

<script>
import ChatUserAvatar from "@/client/components/chat/UserAvatar.vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import useChat from "@/client/extensions/composition/useChat";

export default {
  components: { ChatUserAvatar },
  props: {
    conversationUuid: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ["conversations:conversation-selected"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus, socket } = asyncOperations(props);
    let { chat } = useChat();

    return { asyncOps, asyncOpsReady, asyncStatus, socket: socket.init(), chat };
  },
  data: function () {
    const timestamp = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const userId = this.$store.getters["user/profile"]?.id;
    return {
      currentConversation: 2,
      timestamp: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      sampleDate: new Date(timestamp),
      conversations: false,
      asyncData: {
        conversations: {
          target: "chat/conversation/user/" + userId,
          data: {},
          options: {
            dataMutator: (data) => {
              return data.items;
            },
          },
        },
      },
    };
  },
  async mounted() {
    this.socket = await this.socket;
    await this.socket.waitForUUID();
    this.socket.on("newChatConversation", this.newSocketConversationHandler);
  },
  beforeUnmount() {
    this.socket.off("newChatConversation", this.newSocketConversationHandler);
  },
  methods: {
    async startConversationByState(recipients) {
      //  this.chat.startConversation(recipients, 'test composition');
    },
    selectConversation(conversation) {
      this.$emit("conversations:conversation-selected", { conversation });
    },
    getFirstMessage(conversation) {
      try {
        return conversation.ChatMessages[0];
      } catch (e) {
        return false;
      }
    },
    getConversationUsers(conversation) {
      let result = [];

      if (!conversation.ChatMessages || conversation.ChatMessages.length < 1) {
        return result;
      }

      let msg = conversation.ChatMessages[0];

      if (msg.Sender) {
        result.push(msg.Sender);
      }
      if (Array.isArray(msg.Recipients)) {
        result = [...result, ...msg.Recipients];
      }

      return result;
    },
    getConversationTitle(conversation) {
      let users = this.getConversationUsers(conversation);

      return users.map((user) => user.fullName).join(", ");
    },
    newSocketConversationHandler(data) {
      this.refreshAsyncData();
    },
  },
};
</script>

<style scoped lang="scss">
.user-avatars {
  width: 5em;
  flex: 0 0 5em;
  height: 3em;
  position: relative;
  inset-inline-start: -2px;

  .user-avatar {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 3;

    &:nth-child(1) {
      inset-inline-start: 1.2em;
      z-index: 2;
    }

    &:nth-child(2) {
      inset-inline-start: 2.4em;
      z-index: 1;
    }

    &:nth-child(n + 4) {
      display: none;
    }
  }
}

.content {
  max-width: calc(100% - 6em);
}
.last-message-content,
.conversation-participants {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.conversation-intro {
  position: relative;
  padding-inline: var(--margin-m);

  &.current,
  &.active {
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      inset-inline-start: 0em;
      width: 3px;
      height: 100%;
      background-color: var(--c-lead);
      // border-radius: 0 50% 50% 0;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    height: 1px;
    inset-inline-start: 0;
    width: 100%;
    background-color: var(--c-gray-2);
    margin-top: var(--margin-l);
    margin-inline: auto;
  }
}
</style>
